import React from 'react';

function Blog() {
  return (
    <section id="blog">
      <h2>My Blog</h2>
      <article>
        <h3>Blog Post Title 1</h3>
        <p>Published on: [Date]</p>
        <p>[Brief excerpt of the blog post]</p>
        <a href="#">Read more</a>
      </article>
      <article>
        <h3>Blog Post Title 2</h3>
        <p>Published on: [Date]</p>
        <p>[Brief excerpt of the blog post]</p>
        <a href="#">Read more</a>
      </article>
    </section>
  );
}

export default Blog;
