import React from 'react';

function Projects() {
  return (
    <section id="projects">
      <h2>My Projects</h2>
      <ul>
        <li>Project 1: A web application for task management</li>
        <li>Project 2: Mobile app for fitness tracking</li>
        <li>Project 3: Machine learning model for image classification</li>
        <li>Project 4: Open-source library for data visualization</li>
      </ul>
    </section>
  );
}

export default Projects;